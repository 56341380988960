import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { Frame, TitledSection } from "../../layout"
import { InterviewList, Header } from "../../compositions"
import { PageHeader } from "../../components"
import { Image } from "../../elements"
import { SEO, MDXProvider } from "../../helpers"
import { graphql } from "gatsby"
import {
  InterviewQuery,
  GraphCms_Interview,
  GraphCms_Page,
} from "../../../../graphql-types"

export const query = graphql`
  query Interview($id: String) {
    graphCmsInterview(id: { eq: $id }) {
      ...InterviewFields
    }
    allGraphCmsInterview(sort: { fields: createdAt, order: DESC }, limit: 8) {
      nodes {
        ...InterviewFields
      }
    }
  }
`

type Props = {
  data: InterviewQuery
}

const Interview = ({ data }: Props) => {
  const interview = data.graphCmsInterview as GraphCms_Interview

  return (
    <Frame>
      <SEO page={interview.page as GraphCms_Page} />
      <Header className="z-10" />
      <PageHeader title={interview.title} page={interview.page!} />
      <div className="max-w-screen-md mx-auto py-4 px-3 sm:px-4">
        {interview.page!.image && (
          <Image
            className="block mb-6 rounded"
            gatsbyImageData={
              interview.page!.image.localFile?.childImageSharp?.gatsbyImageData
            }
            alt={interview.page!.title}
          />
        )}

        {interview.page!.markdown && (
          <MDXProvider>
            <MDXRenderer>
              {interview.page!.markdown?.childMdx!.body ?? ""}
            </MDXRenderer>
          </MDXProvider>
        )}
      </div>
      <TitledSection
        title="En Son Eklenen Röportajlar"
        tagline="Yurt dışına gidenler neler yaparak orada iş buldular, ne gibi sorunlarla karşılaşıp o sorunları nasıl çözdüler. Sizler için derlediğimiz röportaj serileri."
        label="Yurt Dışında Yaşam Hakkındaki Röportajlar"
        to="/roportajlar"
        className="bg-blue-light"
      >
        <InterviewList
          interviews={data.allGraphCmsInterview.nodes as GraphCms_Interview[]}
        />
      </TitledSection>
    </Frame>
  )
}

export default Interview
